body {
  display: block;
  margin-top: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
}

/* Animation Keyframes */
@keyframes scale_header {
  0%   {max-height: 0px; margin-bottom: 0px; opacity: 0;}
  100% {max-height: 117px; margin-bottom: 25px; opacity: 1;}
}

@keyframes input_opacity {
  0%   {transform: translateY(-10px); opacity: 0}
  100% {transform: translateY(0px); opacity: 1}
}

@keyframes text_opacity {
  0% {color: transparent;}
}

@keyframes error_before {
  0%   {height: 5px; background: rgba(0, 0, 0, 0.156); color: transparent;}
  10%  {height: 117px; background: #FFFFFF; color: #C62828}
  90%  {height: 117px; background: #FFFFFF; color: #C62828}
  100% {height: 5px; background: rgba(0, 0, 0, 0.156); color: transparent;}
}

main {
  min-height: 250px;
}

.routeIcon {
  width: 33px;
}

.routeIcon--moveLeft {
  position: absolute;
  left: -35px;
  top: 5px;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.reservationItem {
  font-size: 13px;
}

.inlineBlock{
  display: inline-block;
}

.w45 {
  width: 45px;
}

.mLeft15 {
  margin-left: 15px;
}

.mLeft8 {
  margin-left: 8px;
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #c4c5c4 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .5s, opacity 1s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: .3;
  transition: 0s;
}

.app-bar-version {
  font-size:11px;
  position: absolute;
  right: 3px;
  bottom: 3px;
}

.marker-container {
  position: relative;
  text-align: center;
  color: white;
  height: 44px;
  width: 36px;
  //fix marker position
	position: absolute;
	// transform: translateY(-50%);
  top: -25px;
	left:-25px;
}

.marker-container--centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  font-weight: bold;
}

.reservation-map-item {
  position: absolute;
  top: 0;
  width: 43vh;
  height: 100%;
  background-color: white;
  overflow-y: scroll;
}

.cHr {
  border: 0;
  border-top: 1px solid #ccc;
}

.MuiBadge-dot {
  padding: 5px!important;
  animation: blinker 1s linear infinite;
}

@media only screen and (max-width: 640px) {
  .toolbar-p {
    font-size:13px;
  }
}

.toolbar-p {
  margin: 0;
  padding: 0;
}

.inline {
  display: inline-block;
}

.center-block {
  display:block!important;
  margin: 0 auto!important;
}

.ft12 {
  font-size: 12px;
}

.drivers-container {
  background-color: rgba(210, 210, 210, 0.7);
  position: absolute;
  top: 15vh;
  padding: 5px;
  font-size: 11px;
}

.is-paid {
  color: #69e469;
  margin-top: 5px;
  vertical-align: middle;
  position: relative;
  top: -4px;
}

.is-splitted {
  color: orange;
  margin-top: 5px;
  vertical-align: middle;
  position: relative;
  top: -4px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.react-notification-root .n-parent {
  width: 250px!important;
}

.dialog-reservation-actions .MuiDialog-paperWidthSm {
  min-height: 150px;
  top: 25px;
}

.attendace-holder td{
  font-size: 11px;
}

#form-dialog-title {
  padding: 10px 24px 6px 24px;
}

#form-dialog-title h2 {
  font-size: 15px
}

.liftIcon {
  width: 32px!important;
  height: 32px!important;
}

.liftRouteIcon {
  color: #4592e8;
}

.blocked-comm {
  color: red;
}

@import 'styles/login'

